<template>
  <div>
    <div class="row">
      <div class="col-md-3 col-6">
        <label>Sitio</label>
        <select
          class="form-select"
          v-model="filtros.id_sitio"
          @change="filtroTodos()"
        >
          <option value="0">Todos</option>
          <option value="-1">Sin sitio</option>
          <option v-for="sitio in sitios" :key="sitio.id" :value="sitio.id">
            {{ sitio.nombre }}
          </option>
        </select>
      </div>
      <div class="col-md-3 col-6">
        <label>Router</label>
        <select
          class="form-select"
          v-model="filtros.id_router"
          @change="filtrosActualizados()"
        >
          <option value="0">Todos</option>
          <option
            v-for="router in routers"
            :key="router.id"
            :value="router.id"
            >{{ router.nombre }}</option
          >
        </select>
      </div>
      <div class="col-md-2 col-6">
        <label>Mostrar enlaces</label>
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="filtros.enlace"
            @change="
              filtros.enlace = !filtros.enlace,
              filtrosActualizados()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaActivo"
          />
          <label
            class="form-check-label"
            for="banderaActivo"
          >
            {{filtros.enlace ? 'Activos' : 'Inactivos'}}
          </label>
        </div>
      </div>
      <div class="col-md-2 col-6">
        <label>Mostrar cpes</label>
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="filtros.con_cpes"
            @change="
              filtros.con_cpes = !filtros.con_cpes,
              filtrosActualizados()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaCpes"
          />
          <label
            class="form-check-label"
            for="banderaCpes"
          >
            {{filtros.con_cpes ? 'Activos' : 'Inactivos'}}
          </label>
        </div>
      </div>
      <div class="col-md-2 col-6">
        <label>Mostrar sitios</label>
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="filtros.con_sitios"
            @change="
              filtros.con_sitios = !filtros.con_sitios,
              filtrosActualizados()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaSitios"
          />
          <label
            class="form-check-label"
            for="banderaSitios"
          >
            {{filtros.con_sitios ? 'Activos' : 'Inactivos'}}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SitioSrv from '@/services/SitioSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
export default {
  name: 'CmpFiltrosMapa',

  props: ['valoresInicialesFiltros'],
  data: function() {
    return {
      filtros: {
        id_sitio: false,
        id_router: false,
        enlace: false,
        con_cpes: false,
        con_sitios: false,
      },
      sitios: [],
      routers: []
    }
  },
  created: function() {
    var self = this

    if (this.valoresInicialesFiltros) {
      this.filtros = this.valoresInicialesFiltros
    }

    self.refrescarSitios()
    self.cargarRouter()
  },
  methods: {
    filtrosActualizados: function() {
      this.$emit('filtros-actualizados', this.filtros)
    },

    filtroTodos: function() {
      var self = this
      self.filtros.id_router = 0
      self.cargarRouter()
      self.filtrosActualizados()
    },

    palancaActivo: function() {
      var self = this

      if (self.filtros.enlace) self.filtros.enlace = 0
      else self.filtros.enlace = 1
      self.filtrosActualizados()
    },

    refrescarSitios: function() {
      var self = this

      let params = { dato: self.dato }

      SitioSrv.sitiosJSON(params).then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarRouter: function() {
      var self = this
      let idSitio = self.filtros.id_sitio

      RouterSrv.routerSitioJSON(idSitio).then(response => {
        self.routers = response.data
      }).catch(error => {
        if (self.filtros.id_sitio != -1){
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudieron cargar los routers asiginados al sitio'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>