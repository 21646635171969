<template>
  <layout :tituloPagina="`Network | Mapa`">
    <div class="row">
      <div class="col-lg-10">
        <cmp-filtros-mapa
          v-on:filtros-actualizados="actualizarFiltros"
          :valores-iniciales-filtros="filtros"
        ></cmp-filtros-mapa>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 mb-4">
            <filtrador
              v-on:dato-actualizado="
                dato = $event,
                cargarRelsCpesYClientes({},function(){ iniciarMarcadores() }),
                cargarSitios()
              "
              :datoini="dato"
              placeholder="Dato del CPE o el cliente"
            ></filtrador>
            <br>

            <div id="cntTblRels" class="table-responsive">
              <table id="tblRels" class="table table-nowrap">
                <thead>
                  <tr>
                    <th style="width:150px">
                      Cliente
                      <button class="btn btn-sm"
                        @click="actualizarOrden('nombre_cliente');" >
                        <i class="mdi"
                          :class="{
                            'mdi-sort': obtenerOrden('nombre_cliente') == '',
                            'mdi-sort-ascending': obtenerOrden('nombre_cliente') == 'asc',
                            'mdi-sort-descending':obtenerOrden('nombre_cliente') == 'desc',
                            'text-gris': $store.state.layout.layoutMode == 'dark'
                          }"></i>
                      </button>
                    </th>
                    <th style="width:100px">
                      CPE
                      <button class="btn btn-sm"
                        @click="actualizarOrden('nombre_cpe');" >
                        <i class="mdi"
                          :class="{
                            'mdi-sort': obtenerOrden('nombre_cpe') == '',
                            'mdi-sort-ascending': obtenerOrden('nombre_cpe') == 'asc',
                            'mdi-sort-descending':obtenerOrden('nombre_cpe') == 'desc',
                            'text-gris': $store.state.layout.layoutMode == 'dark'
                          }"></i>
                      </button>
                    </th>
                    <th style="width:200px">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rel,index) in relsCpesYClientes" :key="rel.id">
                    <td>
                      <h5 class="text-truncate font-size-14">
                        <router-link
                          :to="{
                            name: 'tableroCliente',
                            params: { id_cliente: rel.id_cliente }
                          }"
                          v-if="rel.id_cliente != null"
                          :title="rel.nombre_cliente"
                        >
                          {{ rel.nombre_cliente }}
                        </router-link>
                      </h5>
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'edicionCpe',
                          params: { id: rel.id_cpe }
                        }"
                      >
                        {{ rel.nombre_cpe }}
                      </router-link>
                    </td>
                    <td>
                      <button
                        class="btn btn-light btn-sm"
                        title="Centrar en cliente"
                        @click="centrarMapaEnMarcador(rel, index)"
                      >
                        <i class="mdi mdi-map-marker-radius fa-lg"></i>
                      </button>
                      <button
                        class="btn btn-light btn-sm"
                        @click="masZoom(rel)"
                      >
                        <i class="mdi mdi-magnify-plus fa-lg"></i>
                      </button>
                      <button
                        class="btn btn-light btn-sm"
                        @click="menosZoom(rel)"
                      >
                        <i class="mdi mdi-magnify-minus fa-lg"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-8">
            <div id="mapa-google">
              <GoogleMap
                ref="mapa"
                :api-key="apikeyGoogle"
                :center="center"
                style="height: 600px;"
                :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
                :zoom="zoom"
              >
                <Marker 
                  v-for="(location, i) in locations"
                  :options="{ 
                    position: location.position,
                    icon: location.icon, 
                    title: location.title 
                  }"
                  @dblclick="zoomChanged($event, location.position)"
                  :key="i"
                >
                  <InfoWindow ref="informacion">
                    <div id="contet">
                      <div id="siteNotice"></div>
                      <h5 id="firstHeading" class="firstHeading">
                        {{ location.informacion.nombre_cliente }}
                      </h5>
                      <div id="bodyContent" style="width: 300px;">
                        <p>
                          <strong>ID de cliente: </strong>{{ location.informacion.numero_cliente }}
                          <br>
                          <strong>Dirección: </strong>
                          {{ location.informacion.calle_cliente }},
                          {{ location.informacion.colonia_cliente }},
                          {{ location.informacion.municipio_cliente }},
                          {{ location.informacion.estado_cliente }}
                          <br>
                          <br>
                          <strong>Router: </strong>{{ location.informacion.nombre_router }} <br>
                          <strong>Sitio: </strong>{{ location.informacion.nombre_sitio }} <br>
                          <strong>CPE: </strong>{{ location.informacion.nombre_cpe }} <br>
                          <strong>Dirección IP: </strong>{{ location.informacion.ip_wan_cpe }} <br> 
                          
                          <br>

                          <a :href="`${baseUrl}/clientes/${location.informacion.id_cliente}/tablero`">Ver registro</a> <br><br>
                          <a :href="'https://maps.google.com/?q='+location.position.lat+','+location.position.lng+'&entry=gps'" target="_blank">
                            Ver en google maps
                          </a>
                        </p>
                      </div>
                    </div>
                  </InfoWindow>
                </Marker>
                <Marker 
                  v-for="(location, i) in locations_sitio" 
                  :options="{ 
                    position: location.position, 
                    icon: location.icon, 
                    title: location.title 
                  }"
                  @dblclick="zoomChanged($event, location.position)"
                  :key="i"
                >
                  <InfoWindow>
                    <div id="contet">
                      <div id="siteNotice"></div>
                      <h5 id="firstHeading" class="firstHeading">
                        {{ location.informacion.nombre_sitio }}
                      </h5>
                      <div id="bodyContent" style="width: 300px;">
                        <p>
                          <strong>Descripción: </strong> {{ location.informacion.direccion_sitio? location.informacion.direccion_sitio: 'Sin descripción' }}
                          <br>
                          <br>
                          <strong>Routers: </strong>
                          <ul>
                            <li v-for="router in location.informacion.routers" :key="router.id">
                              {{ router.nombre }}
                            </li>
                          </ul>
                          <a :href="'https://maps.google.com/?q='+location.position.lat+','+location.position.lng+'&entry=gps'" target="_blank">
                            Ver en google maps
                          </a>
                        </p>
                      </div>
                    </div>
                  </InfoWindow>
                </Marker>
                <Polyline
                  v-show="filtros.enlace" 
                  v-for="(enlace, i) in locations_enlace"
                  :key="i" 
                  :options="enlace"
                ></Polyline>
              </GoogleMap>
            </div>
            <br>
            <div class="row">
              <div class="offset-md-6 col-md-6">
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="mdi mdi-video-input-antenna"></i>&nbsp;Cpes
                  </span>
                  <input
                    class="form-control text-center"
                    :value="relsCpesYClientes.length"
                    placeholder="0" readonly
                  />
                  <span class="input-group-text">
                    <i class="mdi mdi-map-marker-radius-outline"></i>&nbsp;Sitio
                  </span>
                  <input
                    class="form-control text-center"
                    :value="sitios.length"
                    placeholder="0" readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import markerIcon from '@/assets/img/location24x36.png'
import markerIconSitio from '@/assets/img/locationAP.png'
import CmpFiltrosMapa from './CmpFiltrosMapa.vue'
import Filtrador from '@/components/Filtrador.vue'
import CpeSrv from '@/services/CpeSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import { GoogleMap, Marker, InfoWindow, Polyline } from "vue3-google-map"

export default {
  name: 'MapaCpes',
  components: { 
    Layout,
    Filtrador,
    CmpFiltrosMapa,
    GoogleMap,
    Marker,
    InfoWindow,
    Polyline
  },
  data() {
    return {
      baseUrl: window.location.origin,
      filtros: {
        id_sitio: 0,
        id_router: 0,
        enlace: 0,
        con_cpes: 1,
        con_sitios: 1,
      },
      relsCpesYClientes: [],
      sitios: [],
      dato: '',
      orden: 'numero desc',
      posicionInicial: {
        latitud: 0,
        longitud: 0
      },
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      center: { lat: 0, lng: 0 },
      zoom: 13,
      locations: [],
      locations_sitio: [],
      locations_enlace: []
    }
  },
  watch: {
    'filtros.id_sitio': function(newVar, olvVar) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    },
    'filtros.id_router': function(newVar, olvVar) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    },
    'filtros.enlace': function(newVar, olvVar) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    },
    'filtros.con_cpes': function(newVar, olvVar) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    },
    'filtros.con_sitios': function(newVar, olvVar) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    },
    dato: function (newVal,oldVal) {
      this.locations = []
      this.locations_sitio = []
      this.locations_enlace = []
    }
  },

  computed: {
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    }
  },

  created: function() {
    var self = this

    // Carga de los query
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.filtros) this.filtros = this.$route.query.filtros
    if (this.$route.query.filtros) this.filtros = JSON.parse(this.$route.query.filtros)
    
    // Cargas iniciales
    self.cargarRelsCpesYClientes({},function(){ self.iniciarMarcadores() }) // Carga de todos los CPEs de los clientes
    self.cargarSitios() // carga de los sitios

    iu.bus.on('filtros-actualizados', self.actualizarFiltros)
  },

  mounted: function() {
    var self = this

    iu.spinner.mostrar('#mapa-google')

    self.iniciarMapa()
  },

  methods: {
    actualizarFiltros: function(filtros) {
      var self = this

      self.filtros = filtros
      
      self.cargarRelsCpesYClientes({},function(){
        self.iniciarMarcadores()
      })
      self.cargarSitios()
    },

    actualizarOrden(columna) {
      let self = this
      let index = self.orden.search(columna)

      if ( index == -1 ) {
        self.orden = columna + ' asc'
      } 
      else {
        let ordenamiento = self.orden.substring(index + columna.length + 1 )

        switch(ordenamiento) {
          case 'asc':
            self.orden = columna + ' desc';
          break;
          case 'desc': 
            self.orden = '';
          break;
        }
      }

      self.cargarRelsCpesYClientes({},function(){
        self.iniciarMarcadores()
      })
      self.cargarSitios()
    },

    cargarMarcadoresPosicionClientes: function() {
      var self = this

      self.relsCpesYClientes.forEach(function(rel, i) {
        // Si la latitud o longitud es null, se evita continuar
        if (rel.latitud_cpe == null || rel.longitud_cpe == null) return

        var cpeCliente = {
          position: { lat: rel.latitud_cpe, lng: rel.longitud_cpe },
          title: rel.nombre_cliente,
          icon: markerIcon,
          informacion: rel
        }

        self.locations.push(cpeCliente)
      })
    },

    cargarMarcadoresPosicionSitio: function() {
      var self = this

      self.sitios.forEach(function(sitio) {
        // Si la latitud o longitud es null, se evita continuar
        if (sitio.latitud_sitio == null || sitio.longitud_sitio == null) return

        var newSitio = {
          position: { lat: sitio.latitud_sitio, lng: sitio.longitud_sitio },
          title: sitio.nombre_sitio,
          icon: markerIconSitio,
          informacion: sitio
        }

        self.locations_sitio.push(newSitio)
      })
    },

    cargarRelsCpesYClientes: function(config, callback) {
      var self = this
      
      iu.spinner.mostrar('#mapa-google')

      this.$router.replace({
        query: {
          dato: this.dato,
          filtros: JSON.stringify(self.filtros),
          orden: self.orden 
        }
      }).catch (error => {})

      config = config || {}
      if (config.dato == undefined && self.dato != '') config.dato = self.dato
      if (config.id_sitio == undefined && self.filtros.id_sitio != null) config.id_sitio = self.filtros.id_sitio
      if (config.id_router == undefined && self.filtros.id_router != null) config.id_router = self.filtros.id_router

      config.orden = self.orden
      
      self.relsCpesYClientes = []

      if(self.filtros.con_cpes == false) {
        iu.spinner.ocultar('#mapa-google')
        return
      }

      CpeSrv.relsCpesYClientesJSON(config).then(response => {
        self.relsCpesYClientes = response.data

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Ocurrio un error a la hora de cargar la relación de CPEs y clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#mapa-google')
      })
    },

    iniciarMarcadores(){
      var self = this
     
      self.cargarMarcadoresPosicionClientes()
      self.cargarMarcadoresPosicionSitio()
      
      if (self.filtros.enlace == 1) {
        self.cargarEnlaces()
      }
    },

    cargarSitios: function(config) {
      var self = this

      iu.spinner.mostrar('#mapa-google')

      this.$router.replace({
        query: {
          dato: this.dato,
          filtros: JSON.stringify(self.filtros)
        }
      }).catch(error => {})

      config = config || {}
      if (config.dato == undefined && self.dato != '') config.dato = self.dato
      if (config.id_sitio == undefined && self.filtros.id_sitio != null) config.id_sitio = self.filtros.id_sitio
      if (config.id_router == undefined && self.filtros.id_router != null) config.id_router = self.filtros.id_router
        
      self.sitios = []

      if(self.filtros.con_sitios == false) {
        iu.spinner.ocultar('#mapa-google')
        return
      }

      SitioSrv.relsSitiosJSON(config).then(response => {
        self.sitios = response.data
        self.cargarMarcadoresPosicionSitio()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Ocurrió un error a la hora de cargar la relación de los Sitios con los Routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#mapa-google')
      })
    },

    cargarEnlaces: function() {
      var self = this

      iu.spinner.mostrar('#mapa-google')
      
      self.relsCpesYClientes.forEach(function(rel) {
        if(
          rel.latitud_sitio == null || 
          rel.longitud_sitio == null || 
          rel.latitud_cpe == null || 
          rel.latitud_cpe == null
        ) return

        var coordenadas = [
          {
            lat: parseFloat(rel.latitud_sitio),
            lng: parseFloat(rel.longitud_sitio)
          },
          {
            lat: parseFloat(rel.latitud_cpe),
            lng: parseFloat(rel.longitud_cpe)
          }
        ]

        var trayectoriaVuelo = {
          path: coordenadas,
          geodesic: true,
          strokeColor: '#63AD6F',
          strokeOpacity: 1.0,
          strokeWeight: 2
        }

        self.locations_enlace.push(trayectoriaVuelo)
      })

      iu.spinner.ocultar('#mapa-google')
    },

    centrarMapaEnMarcador: function(rel,index) {
      var self = this

      if(rel.latitud_cpe == null || rel.longitud_cpe == null) return

      self.center = {
        lat: rel.latitud_cpe,
        lng: rel.longitud_cpe
      }

      // self.$refs.informacion[index].infoWindow.content.visibility = true
    },

    iniciarMapa: function() {
      var self = this

      iu.spinner.mostrar("#mapa-google")

      SistemaSrv.posicionInicial().then(response => {
        var posicionInicial = response.data

        self.center = {
          lat: parseFloat(posicionInicial.latitud),
          lng: parseFloat(posicionInicial.longitud)
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#mapa-google')
      })
    },

    masZoom: function(rel) {
      var zoom = this.$refs.mapa.map.zoom
      var self = this

      if(rel.latitud_cpe == null || rel.longitud_cpe == null) return

      self.center = {
        lat: rel.latitud_cpe,
        lng: rel.longitud_cpe
      }

      self.zoom = zoom + 2
    },

    menosZoom: function(rel) {
      var zoom = this.$refs.mapa.map.zoom
      var self = this

      if(rel.latitud_cpe == null || rel.longitud_cpe == null) return

      self.center = {
        lat: rel.latitud_cpe,
        lng: rel.longitud_cpe
      }

      self.zoom = zoom - 2
    },

    obtenerOrden (columna) {
      let self = this
      let index = self.orden.search(columna)
      let ordenamiento = ''

      if ( index == -1 ) 
        ordenamiento = ''
      else 
        ordenamiento = self.orden.substring(index + columna.length + 1 )

      return ordenamiento
    },

    zoomChanged: function(event, position) {
      var self = this,
          zoom = this.$refs.mapa.map.zoom

      self.center.lat = 0
      self.center.lng = 0

      self.zoom = zoom + 2

      self.center = {
        lat: position.lat,
        lng: position.lng
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#cntTblRels {
  width: 100%;
  height: 600px;
}

#tblRels {
  min-width: 300px;
}
.text-truncate {
  width: 180px;
}
</style>